$color-tamarillo: #a81010;

@mixin bg-position($x, $y) {
  @if $text-direction == ltr {
    background-position: $x $y;
  } @else {
    background-position: 100% - $x $y;
  }
}

.info-link {
  text-align: left;
}

.appt-book {
  #appointment-book-sections {
    .appointment-booking-location-select {
      .submit_geoloc__submit {
        width: calc(97% - 79px);
      }
    }
    label.error {
      color: $color-red;
    }
    .appointment-booking-review__account-signup-gdpr-compliant {
      .gdpr_compliant__info-right-icon {
        margin-left: 10px;
        overflow: hidden;
        width: 30px;
        height: 30px;
      }
      .gdpr_compliant__info-for-left,
      .gdpr_compliant__info-right-icon {
        float: left;
      }
    }
    .appointment-booking-review__account-signup-form {
      clear: left;
      .signup__mobile-phone-fieldset {
        .signup__mobile-phone {
          width: 77%;
        }
      }
    }
    .appointment-booking-review__error-messages .error-message,
    .appointment-booking-review__error-messages .error-message a {
      color: $color-tamarillo;
      margin: 0.5em 0;
      font-weight: bold;
    }
    #confirmation {
      .appt-date {
        display: inline;
      }
    }
  }
}

.touchevents {
  .appt-book {
    .location-form {
      &__select {
        @include bg-position(97%, 46%);
      }
    }
  }
}

.i18n-en-e-HK {
  .appt-book {
    #appointment-book-sections {
      .date-time .date-time__hour,
      #confirmation .appt-time {
        display: inline;
        word-spacing: -3px;
      }
    }
  }
}

#confirmation {
  .confirmation-container {
    .appt-date-cal {
      .add-cal-link {
        padding-top: 9px;
        padding-bottom: 1px;
        span {
          margin-top: 3px;
        }
      }
    }
  }
}
