.checkout {
  @include breakpoint($medium-up) {
    margin: 10px;
  }
  @include breakpoint($landscape-up) {
    margin: 0 auto;
  }
  .links-panel,
  .offer-code-panel,
  .need-help-panel,
  .tab-content {
    padding: 16px 20px;
    background: $color-gray-background;
    .link {
      border: none;
      display: block;
    }
  }
  .product-list {
    min-height: auto;
  }
  .links-list {
    margin-top: 14px;
    .overlay-link {
      color: $color-black;
    }
  }
  .links-panel,
  .offer-code-panel,
  .need-help-panel {
    margin: 0 10px;
    @include breakpoint($medium-up) {
      margin: 0;
    }
  }
  &__sidebar {
    .order-summary {
      &__content {
        .total-value {
          &__wrapper {
            border-top: none;
          }
        }
      }
    }
    .bottom-viewcart-buttons,
    .tab-content {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .samples-panel,
    .past-purchases-panel {
      .product-list {
        .product {
          margin-top: 15px;
          margin-bottom: 10px;
          .product-img {
            width: 40%;
            float: #{$ldirection};
          }
          .details {
            float: #{$ldirection};
            width: 55%;
            margin-#{$ldirection}: 5%;
            margin-top: 0;
            @include breakpoint($landscape-up) {
              margin-top: 20px;
            }
          }
          .sample-select-button {
            display: inline-block;
            position: relative;
            width: 55%;
          }
        }
      }
    }
    .past-purchases-panel {
      .product-list {
        .product {
          .details {
            @include breakpoint($medium-up) {
              margin-top: 0;
            }
          }
          .shades-list {
            .skus {
              margin-top: 0;
              float: #{$ldirection};
              margin-#{$ldirection}: 5%;
            }
          }
        }
      }
      .more-btn {
        clear: both;
        display: block;
        width: 70%;
      }
    }
    .cart-header {
      display: none !important;
    }
    .viewcart-panel {
      &__title {
        #shopping-bag-title-edit {
          margin: 15px 0;
          @include breakpoint($medium-up) {
            font-size: 28px;
            font-family: $bb-sans;
            line-height: 1;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  &__content {
    .viewcart-panel {
      &__title {
        @include breakpoint($medium-up) {
          border-bottom: 2px solid $color-black;
        }
      }
    }
    .order-summary-panel,
    .registration-panel {
      margin: 15px 20px;
      .order-summary {
        &__content {
          @include breakpoint($medium-up) {
            margin-top: 20px;
          }
          .label,
          .value {
            line-height: 2;
          }
          .shipping {
            &.label,
            &.value {
              @include breakpoint($medium-up) {
                line-height: 4;
              }
            }
          }
        }
      }
      &__title {
        padding-top: 15px;
      }
    }
    .cart-item {
      &__remove-form {
        .remove_link {
          display: none;
          @include breakpoint($medium-up) {
            display: inline-block;
          }
        }
      }
    }
    .shipping-edit-address-content {
      &__title {
        display: block;
        border-bottom: none;
        margin-top: 20px;
        padding-bottom: 0;
        font-size: 21px;
      }
      &__content {
        border-bottom: 0;
        margin: 0;
        header {
          padding: 0;
        }
        .delivery-to-use {
          @include breakpoint($medium-up) {
            margin: 0 20px;
          }
          .radio {
            float: #{$ldirection};
            margin: 12px;
            @include breakpoint($medium-up) {
              margin-top: 20px;
            }
          }
        }
        .shipping-edit-content {
          width: 90%;
          margin: auto;
          @include breakpoint($medium-up) {
            width: 100%;
          }
          .form-item {
            &.title {
              width: 100%;
            }
            &.city,
            &.state {
              width: 49%;
              float: #{$ldirection};
              @include breakpoint($medium-up) {
                width: 47%;
              }
              @include breakpoint($landscape-up) {
                width: 48%;
              }
              label {
                display: block !important;
                .label-content {
                  display: block;
                }
              }
            }
            &.city {
              margin-#{$ldirection}: 5px;
              @include breakpoint($medium-up) {
                margin-#{$ldirection}: 20px;
              }
            }
            &.phone-1 {
              .area-code {
                width: 28%;
                float: #{$ldirection};
                margin-#{$rdirection}: 5px;
                @include breakpoint($medium-up) {
                  width: 40%;
                }
                @include breakpoint($landscape-up) {
                  width: 32%;
                }
                .selectBox-label {
                  color: $color-black;
                }
              }
              .mobile-number {
                display: inline-block;
                width: 70%;
                margin-#{$ldirection}: 0;
                @include breakpoint($medium-up) {
                  width: 56%;
                }
                @include breakpoint($landscape-up) {
                  width: 65%;
                }
              }
            }
            select {
              min-width: auto;
              height: 3em;
            }
          }
        }
        .phone-required {
          display: block !important;
          visibility: visible;
        }
        .new-address,
        .email-sms-promotions {
          width: 100%;
          margin: auto;
          padding: 1em 0;
        }
        .gift-options-display {
          padding: 20px;
          margin: 0;
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 20px 20px 0 0);
            float: #{$rdirection};
            padding: 0;
          }
        }
        .billing-address-display {
          padding: 20px;
          border-top: 1px solid $color-black;
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 20px 0 0 20px);
            width: 43.3%;
            float: #{$ldirection};
            border-top: none;
            padding: 0;
          }
          .payment-address__heading {
            padding-#{$ldirection}: 20px;
            @include breakpoint($medium-up) {
              padding: 0;
            }
          }
        }
        &.active {
          .billing-address-display {
            margin: 0;
          }
        }
      }
    }
    #offer-code-panel {
      .offer-code-panel {
        &__title {
          font-family: $bb-sans;
          line-height: 1;
          font-size: 2em;
          letter-spacing: 1px;
          text-align: #{$ldirection};
          background: $color-gray-background;
        }
      }
    }
  }
  .recommended-products-panel {
    .addtobag {
      &__favorites {
        display: none;
      }
    }
  }
  &.viewcart-empty {
    .samples-pane {
      display: none;
    }
  }
}

.viewcart {
  .links-panel {
    padding: 16px 0;
  }
}

.opc__shipping {
  #shipping-panel {
    .shipping-edit-content {
      .gift-options {
        border-top: 1px solid $color-black;
        .checkout__panel {
          &--header {
            padding: 0;
            .gift-options__title {
              padding: 1em 0;
            }
          }
        }
        .giftwrap {
          padding: 0;
        }
      }
    }
    .selectbox {
      &:hover {
        color: $color-black;
      }
    }
  }
}

.samples-page {
  .product-list {
    .sample-select-button {
      display: block;
    }
    .product {
      @include breakpoint($medium-up) {
        min-height: 475px;
      }
    }
  }
  .samples-buttons {
    &.top {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  .return-link {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .remaining {
    &__header {
      &--alt {
        font-size: 14px;
      }
    }
  }
}

.samples-overlay {
  .product {
    width: 33%;
    padding: 0 5px;
    float: #{$ldirection};
    position: relative;
    min-height: 380px;
    .skus {
      position: absolute;
      #{$ldirection}: 50px;
      #{$rdirection}: 50px;
      bottom: 10px;
    }
    .details {
      text-align: center;
    }
    .sample-select-button {
      display: block;
    }
  }
  .samples-buttons {
    clear: both;
    .add-button {
      text-align: center;
      .btn {
        letter-spacing: 1px;
        background-color: $color-pink;
        color: $color-white;
        padding: 0.75em 1em;
        line-height: 1;
        height: auto;
      }
    }
    &.top,
    .return-link {
      display: none;
    }
  }
  .remaining {
    &__note {
      display: none;
    }
  }
}

.viewcart-panel {
  &__title {
    .edit {
      margin: 25px 0;
    }
  }
  .edit {
    &.link {
      margin: 25px;
    }
  }
  .banner {
    display: none;
  }
  .cart-item {
    &__price {
      clear: both;
      margin-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        clear: none;
      }
    }
    &__qty {
      .selectBox-arrow {
        height: 90%;
        top: 2px;
      }
    }
    &__desc-info {
      .shade {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.review-panel {
  .form-container {
    position: relative;
    .payment-method-radio {
      margin: 10px 0;
      .field {
        margin-top: 15px;
      }
      .payment-text {
        @include swap_direction(margin, 12px 0 0 20px);
        float: #{$ldirection};
      }
      .payment_options_image {
        margin-#{$ldirection}: 10px;
      }
    }
  }
}

.checkout-progress-bar {
  &__list-item {
    width: 50%;
  }
}

#confirmation-page {
  .confirmation-panel {
    &__title {
      letter-spacing: 1px;
      text-align: #{$ldirection};
    }
    &__actions {
      display: block;
    }
    &__content {
      .confirmation-panel {
        &__confirm-text {
          &--not-signedin,
          &--signedin {
            padding: 0;
            text-align: left;
          }
          &--not-signedin {
            .order-id {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .social-login {
      &__terms {
        display: block;
      }
    }
  }
}

.pg_wrapper {
  .bogo-offer {
    .cart-item {
      &__remove-form {
        display: none;
      }
      &__qty {
        padding-top: 45px;
      }
    }
  }
}

#checkout_wp_samples_landing {
  .product-list {
    .skus {
      .sample-select-button {
        &.selected {
          background-color: $color-pink;
        }
      }
    }
  }
}

#registration-panel {
  .mobile-code,
  .mobile-number {
    width: 48%;
    display: inline-block;
    padding: 5px;
  }
  .mobile-code {
    select {
      height: 3em;
      min-width: auto;
    }
  }
  .mobile-number {
    input[type='tel'] {
      margin-bottom: 0;
    }
  }
}

#completed_checkout_registration {
  .registration-form-container {
    width: auto;
    float: unset;
  }
}

.blocked-checkout-btn {
  color: $color-white;
  background-color: $color-black;
  line-height: 35px;
  width: 14%;
  right: 0;
  height: 35px;
  position: absolute;
  text-align: center;
}
