.account-utilities {
  .profile-pic-main {
    .profile-name {
      top: 0;
    }
  }
}

.account-links {
  &__favorites {
    display: none;
  }
}

.profile-info {
  &__pos_msg {
    padding: 0 0 15px 0;
  }
  &__item {
    &__language,
    &__title,
    &__country {
      .selectbox {
        width: 100%;
      }
    }
    &__sex {
      .label {
        display: block;
      }
      .radio {
        display: block;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 10px 10px 0 0);
          display: inline-block;
        }
      }
    }
    .mobile-number-label {
      display: block;
    }
    .mobile-code {
      width: 100%;
      .selectbox {
        width: 100%;
        height: 44px;
        line-height: 3;
        .selectBox-arrow {
          line-height: 2.9;
        }
      }
    }
    .mobile-number,
    .home-number {
      width: 100%;
      @include breakpoint($medium-up) {
        margin-top: 10px;
      }
    }
  }
}

.error {
  color: $color-red;
}

.account {
  .profile-page {
    &__pos_msg {
      padding: 0 0 10px 0;
    }
    .profile-page {
      &__content {
        .form-submit,
        .main-button-wrapper {
          margin-top: 30px;
        }
        .social-info__connect {
          .form-submit {
            margin-top: 0;
          }
          &--tel {
            @include breakpoint($medium-up) {
              pointer-events: none;
              text-decoration: none;
              color: $color-black;
            }
          }
        }
        .optional-info {
          &__birthday-text {
            margin-#{$ldirection}: 10px;
          }
          .birthday {
            .optional-info__item {
              width: 40%;
            }
          }
          &__item__age {
            width: 100%;
            .selectbox {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__new-account {
    input[type='text'] {
      margin-bottom: 20px;
    }
  }
}

.newsletter-info {
  .optional-info__text,
  .email_promotions,
  .newsletter-confirmation,
  .privacy-policy__item {
    margin-#{$ldirection}: 10px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 20px;
    }
  }
}

.privacy-policy-container {
  margin-#{$ldirection}: 10px;
}

.address-book {
  &__controls-link {
    &--make-default {
      padding-top: 10px;
      display: block;
      clear: both;
    }
  }
}

.payment-book {
  &__item {
    padding: 14px 18px;
  }
}

.address-book-page {
  .address-item {
    border: none;
    width: 100%;
    float: none;
    &__address {
      @include breakpoint($medium-up) {
        height: auto;
      }
    }
    &__button {
      @include breakpoint($medium-up) {
        text-transform: uppercase;
        &--delete {
          margin-#{$ldirection}: 50px;
        }
      }
    }
    &__controls {
      margin-top: 10px;
    }
    &__details {
      width: 100%;
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        width: 32.33%;
        display: inline-block;
      }
    }
  }
  .payment-item {
    &__address {
      padding: 0;
    }
  }
  .address_data,
  .payment_data {
    width: 83%;
    float: #{$ldirection};
    @include breakpoint($medium-up) {
      width: 100%;
    }
  }
}

.address-delete-confirm,
.delete-address-confirm__controls {
  margin-top: 20px;
  &-link--delete {
    float: #{$ldirection};
  }
  &-link--cancel {
    float: #{$rdirection};
  }
}

.address-form {
  .state_container {
    .state_select {
      width: 100%;
    }
    .label-content {
      display: inline-block;
    }
  }
  .phone-1 {
    clear: both;
    float: #{$ldirection};
    width: 100%;
  }
  .mobile-number-label {
    display: block;
  }
  .mobile-code {
    width: 27%;
    float: #{$ldirection};
    .selectbox {
      min-width: 100px;
    }
  }
  .mobile-number,
  .home-number {
    width: 70%;
    float: $ldirection;
    margin-#{$ldirection}: 3%;
  }
  .default-shipping {
    clear: both;
    .label-content {
      display: inline-block;
    }
  }
  &__item {
    label {
      &.error {
        border: none;
      }
    }
  }
}

.order-details-page {
  &__header {
    text-align: center;
    border-bottom: 1px solid $color-gray-border;
    padding-bottom: 20px;
  }
  &__content {
    .cart-item {
      clear: #{$ldirection};
      &__desc {
        @include breakpoint($medium-up) {
          width: 30%;
        }
        @include breakpoint($landscape-up) {
          width: 35%;
        }
      }
      &__price {
        @include breakpoint($medium-up) {
          width: 20%;
        }
      }
      &__qty {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 10%;
        }
        @include breakpoint($landscape-up) {
          width: 14%;
        }
      }
      &__total {
        @include breakpoint($medium-up) {
          width: 17%;
        }
        @include breakpoint($landscape-up) {
          width: 14%;
        }
      }
      &__qty-label {
        display: inline-block;
      }
    }
    .order-info {
      &__item {
        @include breakpoint($medium-up) {
          width: 25%;
          float: #{$ldirection};
          margin: 0 40px 10px 0;
        }
        &--header {
          @include breakpoint($medium-up) {
            border-bottom: 1px solid $color-gray-border;
            padding-bottom: 10px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .order-status {
      width: 100%;
    }
    .order-details {
      @include breakpoint($medium-up) {
        clear: #{$ldirection};
      }
      &__item {
        @include breakpoint($medium-up) {
          width: 25%;
          float: #{$ldirection};
          margin-#{$rdirection}: 40px;
        }
      }
    }
    .order-products {
      @include breakpoint($medium-up) {
        clear: #{$ldirection};
        padding-top: 20px;
        border-bottom: 1px solid $color-gray-border;
      }
      .cart__header {
        &--item {
          float: #{$ldirection};
        }
        &--product {
          width: 48%;
        }
        &--price {
          width: 23%;
        }
        &--qty {
          width: 17%;
        }
        &--total {
          width: 12%;
        }
      }
      &--header {
        display: none;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 10px 0 10px 0);
          display: block;
          padding: 10px;
          border-bottom: 1px solid $color-gray-border;
          overflow: hidden;
          border-top: 1px solid $color-gray-border;
          font-weight: 400;
          font-family: $brandon-text-bold;
        }
      }
    }
  }
}

.past-purchases {
  &__content {
    .product-info {
      &__description {
        width: 100%;
        float: #{$ldirection};
        @include breakpoint($medium-up) {
          width: 73%;
        }
      }
    }
    .created-date {
      @include breakpoint($medium-up) {
        width: 16%;
      }
      @include breakpoint($landscape-up) {
        width: 12%;
      }
    }
    .trans-id {
      @include breakpoint($medium-up) {
        width: 20%;
      }
      @include breakpoint($landscape-up) {
        width: 22%;
        text-align: center;
      }
    }
    .price {
      @include breakpoint($medium-up) {
        width: 14%;
      }
      @include breakpoint($landscape-up) {
        width: 12%;
      }
    }
    .order-status {
      a {
        margin-#{$ldirection}: 10px;
      }
      @include breakpoint($medium-up) {
        width: 20%;
        a {
          margin-#{$ldirection}: 0;
        }
      }
      @include breakpoint($landscape-up) {
        width: 23%;
        text-align: center;
      }
    }
    .tracking-link {
      @include breakpoint($medium-up) {
        width: 23%;
        text-align: center;
      }
    }
    .past-purchases-data-item {
      .tracking--link {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
    .view-details {
      @include breakpoint($medium-up) {
        width: 4%;
      }
    }
  }
}

input[type='file'] {
  cursor: pointer;
  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.account__section {
  .opt-out {
    &__item {
      margin: 10px;
      @include breakpoint($medium-up) {
        margin: 20px;
      }
    }
  }
}

.sign-in-page {
  .mobile-phone {
    .label {
      display: block;
    }
    .mobile-code,
    .mobile-number {
      width: 48%;
      display: inline-block;
      select,
      input[type='text'] {
        width: 100%;
        height: 32px;
      }
    }
  }
  .fs.email_promotions {
    .email-promo {
      .field_label {
        display: block;
        visibility: visible;
      }
    }
  }
}

.account-page {
  .account-page__content {
    .account-profile {
      &__email {
        word-break: break-word;
      }
    }
  }
}
