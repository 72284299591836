.site-footer {
  .language-toggle {
    @include breakpoint($medium-up) {
      max-width: 1440px;
      margin: 0 auto;
    }
    &__content {
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: #{$ldirection};
        margin-#{$ldirection}: 60%;
        padding: 0 20px;
      }
      .lang-zh,
      .lang-en {
        color: $color-white;
        display: inline-block;
      }
      a {
        &:hover {
          color: $color-red;
        }
      }
    }
  }
}

.site-header {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: auto;
        padding-bottom: 6px;
      }
    }
    .cart-products {
      .prod {
        .qty {
          font-size: 16px;
        }
      }
    }
  }
}

.customer-service-section {
  .chat-now {
    display: block;
    a {
      bottom: auto;
    }
  }
  .cs-contact-form {
    &__radio {
      display: inline-block;
      margin-#{$rdirection}: 20px;
    }
  }
}

select {
  &::-ms-expand {
    display: none;
  }
}

[lang='en-HK'],
.en_HK {
  .product-brief,
  .product-full {
    &__sub-line {
      display: none;
    }
  }
  .cart-product-name_sub-line,
  .product_result_sub-line {
    display: none;
  }
  .spp-product-layout {
    .sticky-add-to-bag {
      &__name {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.popup-offer,
.site-email-signup__form,
.login,
.registration {
  .optional-info__header,
  .sign-in-component__birthday-program {
    display: none;
  }
  .site-email-signup {
    &__submit {
      margin-top: 10px;
    }
  }
}

.store-locator {
  &--desktop {
    .store-locator {
      &__dutyfree {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-family: $brandon-text;
  }
}

textarea {
  &.error {
    border: 1px solid $color-red;
  }
}

.hero-content-block {
  .content-block {
    &__picture {
      .mantle-media-asset {
        @include breakpoint($large-up) {
          height: 690px;
        }
      }
    }
  }
}

.lp_buttons_area {
  .lp_submit_button,
  .lp_confirm_button,
  .lp_close_survey_button {
    &:hover {
      background: $color-black !important;
    }
  }
}

.lp_pagination-area {
  .lp_buttons_area {
    .lp_next_button {
      background: $color-black !important;
    }
  }
}

.product-full__store-check {
  display: none;
}

#search-results {
  .button {
    &--disabled {
      color: $color-white;
      background-color: $color-light-gray;
      border: $color-light-gray;
    }
  }
}

.search-results {
  &__header {
    .search-form {
      &__results-count {
        line-height: 35px;
        #{$rdirection}: 181px;
      }
      input[type=submit].search-form__submit {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

.marketing__wrapper {
  .marketing__enrollment {
    &__guest__button {
      line-height: 2;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      padding: 9px 50px;
    }
    &__email,
    &__button {
      display: none;
    }
    &__button__loyalty {
      display: inline-block;
    }
  }
  .earning-points--marketing {
    .earning-points__data {
      &__header,
      &__footer {
        font-size: 16px;
      }
      &__text {
        line-height: 1;
        font-size: 44px;
      }
    }
  }
  .marketing__benefits__section {
    &__header__benefits {
      @include breakpoint($medium-up) {
        width: 25%;
        padding-#{$ldirection}: 35px;
      }
    }
    &__header__tier {
      padding-#{$rdirection}: 0;
      @include breakpoint($medium-up) {
        width: 25%;
      }
      &.tier-one {
        background-color: $lr2021-tier-one-bg-color;
        border-color: $lr2021-tier-one-border-color;
        border-bottom: 25px solid $lr2021-tier-one-border-color;
      }
      &.tier-two {
        background-color: $lr2021-tier-two-bg-color;
        border-color: $lr2021-tier-two-border-color;
        border-bottom: 25px solid $lr2021-tier-two-border-color;
        @include breakpoint($medium-portrait-only) {
          width: 26%;
        }
      }
      &.tier-three {
        background: linear-gradient(
          90deg,
          $lr2021-tier-three-bg-gradient-color-1 0,
          $lr2021-tier-three-bg-gradient-color-2 100%
        );
        border-color: $lr2021-tier-three-border-color;
        border-bottom: 25px solid $lr2021-tier-three-border-color;
      }
    }
    &__content {
      .marketing__benefits {
        &__section__row__tier {
          @include breakpoint($medium-up) {
            width: 25%;
            padding: 20px 30px;
          }
          .marketing__benefits__content {
            margin-top: 10px;
          }
        }
        &__section__row__header {
          @include breakpoint($medium-up) {
            width: 24%;
            padding-#{$ldirection}: 20px;
          }
        }
      }
    }
  }
  .earning-points__section {
    padding: 30px 10px;
    @include breakpoint($large-up) {
      width: 30%;
    }
  }
}

.checkout-confirmation-page {
  .checkout__content {
    .registration-panel {
      margin: 0;
      @include breakpoint($medium-up) {
        margin: 15px 20px;
      }
      &__content__right {
        padding: 0;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 46%;
        }
        .checkout__panel__guest__img {
          padding-top: 30px;
          &__container {
            height: 235px;
            margin-bottom: 0;
          }
        }
      }
      &__content__left {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 50%;
        }
        padding-#{$rdirection}: 0;
        .checkout__panel {
          &__guest__subtitle {
            padding-top: 30px;
            margin-bottom: 15px;
            line-height: 1.2;
          }
          &-content {
            padding: 0;
          }
        }
        .checkout_enroll_cta_loyalty {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

.loyalty-page__content {
  .earning-points {
    &--loyalty {
      text-align: center;
      .earning-points {
        &__section {
          width: auto;
          padding: 30px 10px;
          @include breakpoint($large-up) {
            width: 30%;
          }
        }
        &__data__text {
          font-size: 48px;
        }
      }
    }
    &__data__footer {
      font-size: 16px;
    }
  }
  .loyalty__panel__offers {
    &__sku__list-container {
      .tabbed-rewards-block {
        &__tabs {
          padding: 0;
        }
        &__tab {
          width: 33%;
          &.rewards-eligible {
            color: $color-black;
          }
        }
        &__content-bottom-description {
          margin-bottom: 20px;
        }
      }
      .loyalty__panel {
        &__offers__sku__cta {
          margin-top: 15px;
        }
      }
    }
    &__offer-image-container {
      .loyalty__panel__offers {
        &__offer-image {
          border: 1px solid $color-grey-light;
          height: 275px;
          margin: 0 auto;
          @include breakpoint($large-up) {
            height: 248px;
          }
        }
      }
    }
    &__sku__product__image-container {
      border: 1px solid $color-grey-border;
      margin-bottom: 10px;
      height: 250px;
    }
  }
  .loyalty__panel__points {
    &__stats .points__current {
      color: $color-black !important;
      display: block;
      @include breakpoint($large-up) {
        display: inline-block;
      }
    }
    &__header {
      display: none;
    }
    &__right {
      .loyalty__panel {
        &__points__info-section {
          &.next-tier {
            .info-link {
              display: inline;
            }
          }
        }
      }
    }
  }
  .loyalty__panel {
    &__transactions__list {
      margin-#{$rdirection}: 10px;
    }
    &__offers__limits {
      border: none;
      margin-top: 0;
    }
  }
}

.sign-in-page {
  .new-account {
    &__item {
      &.loyalty_accept {
        .label {
          display: block;
          visibility: visible;
        }
      }
    }
  }
}

.checkout__content {
  .loyalty-points-content {
    &__summary {
      position: relative;
      &__non-member-content {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid $color-black;
        border-top: 1px solid $color-black;
        padding: 10px 0;
      }
      .loyalty-info-icon {
        position: absolute;
        font-size: 9px;
        border: 1px solid $color-gray-dark;
        width: 15px;
        height: 15px;
        display: block;
        border-radius: 50%;
        text-align: center;
        top: 10px;
        #{$rdirection}: 30px;
        cursor: pointer;
        line-height: 1.4;
      }
      .loyalty-tooltip-content {
        display: none;
      }
    }
  }
  .shipping-panel {
    a {
      &.btn {
        &.edit {
          bottom: 7px;
          top: 8px;
          @include swap_direction(padding, 8px 15px);
        }
      }
    }
    .ship-display-edit,
    .bill-display-edit {
      .btn {
        margin-top: 10px;
        @include swap_direction(padding, 8px 15px);
      }
    }
  }
}

.checkout__sidebar {
  .loyalty-points-content {
    &__summary {
      position: relative;
      .loyalty-info-icon {
        top: 10px;
        #{$rdirection}: 0;
      }
    }
  }
  .viewcart-header {
    #shopping-bag-edit {
      bottom: 8px;
      @include swap_direction(padding, 8px 15px);
    }
  }
}

.tooltipster {
  &-default,
  &-base {
    background: $color-white;
    border-radius: 10px;
    border: 1px solid $color-black;
    .tooltipster-content {
      color: $color-black;
      background: $color-white;
      border-radius: 10px;
      text-align: $ldirection;
    }
  }
}

.pr-war {
  .pr-loyaltymember2-form-group,
  .pr-bbclubmember2-form-group {
    display: none;
  }
}

.write_a_review {
  &__container {
    .thank-you-page {
      #loyaltymember2,
      #bbclubmember2 {
        display: none;
      }
    }
  }
}

.earning-points {
  font-size: 16px;
}

.loyalty__panel__offers {
  &__sku__list {
    .loyalty__panel__offers {
      &__sku__loader__button {
        &.button—freeze {
          background: $color-light-gray;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          color: $color-white;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 1;
          letter-spacing: 1px;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
}

.section-loyalty-program {
  .marketing__wrapper {
    .earning-points__section {
      width: 80%;
      padding: 50px 10px;
      margin: 5px auto;
      max-width: 250px;
      @include breakpoint($medium-up) {
        width: 18%;
        margin: 5px;
        padding: 50px 10px;
      }
    }
  }
}

.marketing__enrollment {
  display: none;
}

// logged in
.elc-user-state-logged-in {
  .marketing__enrollment__account {
    display: block;
  }
  .marketing__enrollment__join {
    display: none;
  }
}
// logged out
.elc-user-state-anonymous {
  .marketing__enrollment__account {
    display: none;
  }
  .marketing__enrollment__join {
    display: block;
  }
}

.marketing__terms_conditions,
.marketing__footernote_content {
  text-align: #{$ldirection};
  padding: 5px 20px;
}

.account-breadcrumb-wrapper {
  text-align: center;
}

.account-breadcrumb {
  color: $color-black;
  text-decoration: none;
}

.loyalty__panel__points__stats .points__current {
  color: $color-black !important;
}

.loyalty__panel__offers__offer {
  padding: 2px;
  &-image {
    width: 98%;
  }
}

.loyalty__panel__points__info {
  &-rewards-container {
    text-align: center;
    margin-#{$rdirection}: 0;
    margin-top: 20px;
    margin-bottom: 0;
    @include breakpoint($large-up) {
      text-align: $rdirection;
      margin-#{$rdirection}: 5px;
      margin-bottom: 20px;
      margin-top: 0;
    }
    a {
      color: $color-gray-dark;
    }
  }
  &-container {
    margin-top: 0;
  }
}

.tier-1 {
  .loyalty__panel__points__info-section {
    background-color: $lr2021-tier-one-bg-color;
    border-#{$rdirection}: 12px solid $lr2021-tier-one-border-color;
    @include breakpoint($large-up) {
      border-#{$rdirection}: 0;
      border-bottom: 12px solid $lr2021-tier-one-border-color;
    }
  }
}

.loyalty__panel__points.tier-2 {
  .loyalty__panel__points__info-section,
  .loyalty__panel__points__title__seperator {
    background-color: $lr2021-tier-two-bg-color;
    border-#{$rdirection}: 12px solid $lr2021-tier-two-border-color;
    @include breakpoint($large-up) {
      border-#{$rdirection}: 0;
      border-bottom: 12px solid $lr2021-tier-two-border-color;
    }
  }
}

.tier-3 {
  .loyalty__panel__points__info-section {
    background: linear-gradient(
      90deg,
      $lr2021-tier-three-bg-gradient-color-1 0,
      $lr2021-tier-three-bg-gradient-color-2 100%
    );
    border-#{$rdirection}: 12px solid $lr2021-tier-three-border-color;
    @include breakpoint($large-up) {
      border-#{$rdirection}: 0;
      border-bottom: 12px solid $lr2021-tier-three-border-color;
    }
  }
}

// hide reward link from loyalty page
.account__section--loyalty {
  .loyalty__panel__points__info-rewards-container {
    span {
      display: none;
    }
  }
}

.loyalty__panel__offers__sku__loader__button {
  &.loyalty__panel__product__button {
    &.button--freeze {
      pointer-events: none;
      cursor: none;
      background: $color-light-gray;
    }
  }
}

.marketing__benefits__header__wrapper {
  .marketing__benefits__header {
    @include breakpoint($medium-up) {
      font-size: 65px;
    }
  }
}

.loyalty_join_signin_wrapper {
  .marketing__banner {
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 50px 60% 50px 13%);
      background-size: 100% 100%;
      height: 345px;
    }
    &__header {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto 30px);
      }
    }
  }
}

.device-mobile {
  .marketing__banner {
    position: relative;
    &__header {
      position: absolute;
      top: 47%;
      z-index: 10;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
    &__text {
      position: absolute;
      color: $color-white;
      top: 64%;
      z-index: 10;
      border: none;
      margin: 0 12px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
  .marketing__enrollment {
    &__account,
    &__join {
      position: absolute;
      top: 85%;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
  .marketing__benefits__section {
    &--tier-one {
      .marketing__benefits__section__header {
        background-color: $lr2021-tier-one-bg-color;
        border-color: $lr2021-tier-one-border-color;
        border-bottom: 25px solid $lr2021-tier-one-border-color;
      }
    }
    &--tier-two {
      .marketing__benefits__section__header {
        background-color: $lr2021-tier-two-bg-color;
        border-color: $lr2021-tier-two-border-color;
        border-bottom: 25px solid $lr2021-tier-two-border-color;
      }
    }
    &--tier-three {
      .marketing__benefits__section__header {
        background: linear-gradient(
          90deg,
          $lr2021-tier-three-bg-gradient-color-1 0,
          $lr2021-tier-three-bg-gradient-color-2 100%
        );
        border-color: $lr2021-tier-three-border-color;
        border-bottom: 25px solid $lr2021-tier-three-border-color;
      }
    }
  }
}

.opc__shipping {
  .checkout__panel {
    &.display {
      display: block;
    }
  }
}

.loyalty__panel__transactions__table {
  .icon-arrow_down,
  .icon-arrow_up {
    &::before {
      position: relative;
      #{$rdirection}: 30px;
    }
  }
}

.section-bobbi-brown-club-terms-conditions {
  #console {
    .messages {
      &.public {
        display: none;
      }
    }
  }
}

.loyalty__panel__transactions {
  &__transaction__collapsed__content {
    padding: 0 26%;
  }
}
