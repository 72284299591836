body {
  &.ajax-wait,
  &.ajax-wait * {
    cursor: progress;
  }
}

.product-full {
  &__store-check {
    &::after {
      clear: both;
      display: table;
      content: '';
    }
    .product-store-check--v2 {
      .product-store-check {
        &__inline--v2 {
          display: block;
        }
      }
      .location-controls {
        padding-#{$ldirection}: 0;
      }
      .psc-state-init {
        .inline-container {
          &__partner {
            display: none;
          }
          &__link {
            display: block;
          }
        }
      }
    }
    .find-in-store {
      @include swap_direction(margin, 0 10px 15px 10px);
      display: block;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      border: 1px solid $color-black;
      padding: 5px 0;
      color: $color-black;
      text-decoration: none;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 0 15px 0);
      }
    }
  }
}

.product-store-check--v2 {
  .product-store-check {
    &__inline--v2,
    &__overlay--container {
      .inline-errors {
        color: $color-red;
        font-size: 14px;
      }
      .inline-container {
        .location-controls {
          &__hdr {
            margin-#{$rdirection}: 4px;
          }
          &__info {
            margin-#{$rdirection}: 4px;
            font-size: 14px;
          }
          &__link {
            margin-#{$rdirection}: 4px;
          }
          &__distance--select {
            display: none;
          }
        }
        .edit-controls {
          display: block;
          padding-#{$ldirection}: 0;
          .location-controls__input {
            @include breakpoint($medium-up) {
              width: 40%;
            }
          }
        }
        &__controls {
          padding: 10px;
          .avail-info__msg--span {
            display: none;
          }
        }
        &__partner,
        &__link {
          padding-top: 10px;
        }
        &__doors {
          .doors-container {
            .container-row {
              &__doors {
                .door-row {
                  .door-info {
                    &__avail {
                      padding-#{$ldirection}: 1em;
                      .avail-info {
                        &__msg {
                          width: 45%;
                          @include breakpoint($medium-up) {
                            width: 25%;
                          }
                          &--text {
                            font-size: 14px;
                            display: inline-block;
                            margin-#{$ldirection}: 0;
                            width: 80%;
                            @include breakpoint($medium-up) {
                              display: inline;
                            }
                          }
                          &--span {
                            @include swap_direction(margin, 0.15em 0.25em 0 0);
                            border-radius: 50%;
                            display: inline-block;
                            height: 0.75em;
                            width: 0.75em;
                            padding: 0.2em;
                            &.msg-span {
                              &__avail {
                                background-color: $color-green;
                              }
                              &__not-avail {
                                background-color: $color-red;
                              }
                              &__call {
                                background-color: $color-orange;
                              }
                            }
                          }
                        }
                      }
                      .door-info {
                        &__address--distance {
                          width: 20%;
                        }
                        &__address--name {
                          width: 30%;
                          padding-#{$rdirection}: 10px;
                        }
                        &__address--phone {
                          width: 30%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &__overlay {
      .location-controls {
        &__close {
          color: inherit;
          &:before {
            content: '\E010';
          }
        }
        &__info {
          margin-bottom: 15px;
        }
        &__distance {
          display: none;
        }
        &__btn--zip {
          float: none;
        }
      }
      .overlay-container {
        .location-controls {
          &__distance--select {
            padding-#{$rdirection}: 0;
            min-width: 76px;
            padding: 0 5px;
            background-position-x: 93%;
          }
        }
        .doors-container {
          .container-row {
            &__doors {
              .door-row {
                .door-info {
                  &__avail {
                    .avail-info {
                      &__msg {
                        width: 40%;
                        &--text {
                          font-size: 14px;
                        }
                        &--span {
                          display: inline-block;
                          position: inherit;
                        }
                      }
                    }
                  }
                  &__address {
                    padding-top: 0;
                    width: 55%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .edit-controls {
    display: block;
  }
  .location-controls {
    padding-#{$ldirection}: 0;
    &.edit-controls {
      .location-controls {
        &__hdr {
          @include swap_direction(margin, 0 0 10px 0);
          width: 100%;
          height: 30px;
          border-bottom: 1px solid $color-black;
        }
      }
    }
    &__zip {
      margin-bottom: 10px;
      &--input {
        margin-#{$rdirection}: 2%;
        border: 1px solid $color-black;
        height: 41px;
        line-height: normal;
        background-position-x: 97%;
        background-color: $color-white;
        display: inline-block;
      }
    }
    &__btn--zip {
      height: 100%;
      line-height: 20px;
      width: 21%;
      float: none;
    }
    &__loading {
      text-align: center;
    }
  }
}
